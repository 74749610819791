@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-Black.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Black.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-BlackItalic.woff2") format("woff2"),
    url("../src/fonts/Satoshi-BlackItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-BoldItalic.woff2") format("woff2"),
    url("../src/fonts/Satoshi-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-Italic.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-Light.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-LightItalic.woff2") format("woff2"),
    url("../src/fonts/Satoshi-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-MediumItalic.woff2") format("woff2"),
    url("../src/fonts/Satoshi-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("../src/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@keyframes bounceIn {
  0%,
  100% {
    transform: translateX(-20%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.arrowRight {
  animation: 1s bounceIn infinite;
}

/* global css starts here */

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

figure {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
ol,
ul,
span,
label {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

pre {
  white-space: break-spaces;
  font-family: "Satoshi";
}

h1 {
  line-height: 1.2;
  font-weight: 600;
  font-size: 2.4em;
  color: #000;
}
h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.8px;
  color: #1c1c1c;
}

input {
  display: block;
  width: 100%;
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: inset 0 0 20px 50px #f1f4f6;
}

.schedule-field input:-webkit-autofill,
.schedule-field input:-webkit-autofill:hover,
.schedule-field input:-webkit-autofill:focus,
.schedule-field input:-webkit-autofill:active {
  box-shadow: inset 0 0 20px 50px #f1f4f6;
}

.reset-field input:-webkit-autofill,
.reset-field input:-webkit-autofill:hover,
.reset-field input:-webkit-autofill:focus,
.reset-field input:-webkit-autofill:active {
  box-shadow: inset 0 0 20px 50px #dedede;
}

.dark-field input:-webkit-autofill,
.dark-field input:-webkit-autofill:hover,
.dark-field input:-webkit-autofill:focus,
.dark-field input:-webkit-autofill:active {
  box-shadow: inset 0 0 30px 50px white;
}

.container {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.others .others-slider .slick-track {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.others .item {
  display: flex !important;
}

.main-header .desktop-nav .active {
  color: #1e90fe;
}

/* landing page css start */
.ladingPageHero {
  background: url("../src/images/landing_page_new_bg.jpg") no-repeat;
  background-size: cover;
}
/* landing page css end */

/* form group checkbox css */

.form-group input::before {
  content: "";
  background-color: #fff;
  border: 2px solid #ccd0d4;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-group input:checked::before {
  border-color: #1e90fe;
}
.form-group input:checked::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 1px;
  background-color: #1e90fe;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-group:has(input:disabled) label {
  color: #485867;
  opacity: 0.9;
}
.form-group input:disabled::before {
  border-color: #ccd0d4;
}
.form-group input:disabled::after {
  background-color: #ccd0d4;
}
.form-group.form-group-lead input::before {
  content: "";
  background-color: #fff;
  border: 2px solid #ccd0d4;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-group.form-group-lead input:checked::before {
  border-color: #1e90fe;
}
.form-group.form-group-lead input:checked::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 1px;
  background-color: #1e90fe;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notification-table .form-group input:disabled::before {
  background-color: #f1f4f6;
  border-color: #f1f4f6;
}
.support-list .card:hover p,
.support-list .card:hover h5,
.support-list .card:hover svg {
  color: white;
  transition: 0.3s;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1320px;
    width: 100%;
  }
}
/* global css end here */

/* healthService css start here */

.health-services .container {
  margin-bottom: -82px !important;
}
/* healthService css end here */

.dot {
  transform: translateX(-50%);
  transition: background-color 0.5s ease-in-out;
}

.dot.active {
  background-color: #1e90fe;
  width: 28px;
  height: 28px;
  top: -11px;
}

.dot::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
  transition: 0.4s;
}

.dot.active::after {
  background-color: #fff;
}
.dot.previous {
  opacity: 0;
}

.range-items li.active {
  color: #1e90fe;
  font-weight: 900;
}

.range-items li {
  position: relative;
}

.range-items li::after {
  content: "";
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  background-color: #ccd0d4;
  border-radius: 50%;
  transition: 0.4s;
}

.range-items li:last-child::after {
  display: none;
}

.dashboard-messages::-webkit-scrollbar {
  width: 0.3rem;
}

.dashboard-messages::-webkit-scrollbar-track {
  background-color: #ebeff3;
}

.dashboard-messages::-webkit-scrollbar-thumb {
  background-color: #1e90fe;
  border-radius: 20px;
}

.form-group2 ul li input[type="radio"],
.form-group3 ul li input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.form-group2 ul li,
.form-group3 ul li {
  position: relative;
}

.form-group2 ul li label,
.form-group3 ul li label {
  padding-left: 36px;
  z-index: 9;
  cursor: pointer;
  transition: 0.4s;
}

.form-group2 ul li .check,
.form-group3 ul li .check {
  display: block;
  position: absolute;
  border: 1px solid #ccd0d4;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  top: 0;
  left: 0px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
  cursor: pointer;
}

.form-group2 ul li .check::before,
.form-group3 ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 16px;
  width: 16px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
}

.form-group2 input[type="radio"]:checked ~ .check,
.form-group3 input[type="radio"]:checked ~ .check {
  border: 1px solid #1e90fe;
  background-color: #fff;
}

.form-group2 input[type="radio"]:checked ~ .check::before,
.form-group3 input[type="radio"]:checked ~ .check::before {
  background: #1e90fe;
}

.form-group3 ul li .check {
  height: 20px;
  width: 20px;
  top: 3px;
  background-color: #f1f4f6;
  border: 1px solid #ebeff3;
}

.form-group3 ul li .check::before {
  height: 10px;
  width: 10px;
  top: 4px;
  left: 4px;
}

.form-group3.display ul li .check {
  background-color: white;
}

.form-group3 input[type="radio"]:checked ~ label {
  color: #1c1c1c;
}

.dashboard-messages::-webkit-scrollbar,
.scroller::-webkit-scrollbar {
  width: 0.3rem;
}

.dashboard-messages::-webkit-scrollbar-track,
.scroller::-webkit-scrollbar-track {
  background-color: #ebeff3;
}

.dashboard-messages::-webkit-scrollbar-thumb,
.scroller::-webkit-scrollbar-thumb {
  background-color: #1e90fe;
  border-radius: 20px;
}
.lead-scroller::-webkit-scrollbar {
  width: 0.3rem;
}

.lead-scroller::-webkit-scrollbar-track {
  background-color: #ebeff3;
}

.lead-scroller::-webkit-scrollbar-thumb {
  background-color: #a7afb7;
  border-radius: 20px;
}
.suf-credit .range-items li::after {
  display: none;
}

.table-scroller::-webkit-scrollbar {
  width: 100%;
  height: 2px;
}

.table-scroller::-webkit-scrollbar-track {
  background-color: #ebeff3;
}

.table-scroller::-webkit-scrollbar-thumb {
  background-color: #1e90fe;
  border-radius: 20px;
}

/* .suf-credit .range-items li:nth-child(3)::before {
  content: "";
  background: url(../src/images/bonus_credit.svg) no-repeat;
  width: 95px;
  height: 21px;
  position: absolute;
  left: -11px;
  top: -23px;
} */
.suf-credit .range-items {
  margin-top: 35px;
}
.suf-credit-inactive .range-items {
  margin-top: 20px;
}

.suf-credit .text-on-dot {
  display: block;
}

.tooltip-icon:hover .tooltip {
  visibility: visible;
}

.bg-color-change {
  background-color: #fff;
}

.unmatched-lead-info li {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.unmatched-lead-info li h6,
.unmatched-lead-info li span {
  max-width: 100% !important;
  width: 100% !important;
}

.lead-info li:nth-child(even) {
  background-color: #f1f4f6;
}
.lead-info li span {
  /* line-break: anywhere; */
}

.ques-ans li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.datepicker-main .react-datepicker-wrapper {
  width: 100%;
}
.datepicker-main .react-datepicker-wrapper svg {
  padding: 0 10px 0 15px;
  border-right: 1px solid #ebeff3;
  width: 24px;
  height: 24px;
}
.datepicker-main .react-datepicker__input-container {
  display: flex;
  align-items: center;
}
.datepicker-main .react-datepicker-wrapper input {
  padding-left: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #485868;
}
.quill-wrapper .quill {
  background-color: #fff;
  border: 1px solid #ebeff3;
  border-radius: 6px;
}
.quill-wrapper .ql-toolbar.ql-snow {
  padding: 18px 8px;
  border: none;
}
.quill-wrapper .ql-container.ql-snow {
  height: 310px;
  border: none;
  padding: 0 18px;
}
.quill-wrapper .ql-editor {
  padding: 30px 0;
  border-top: 1px solid #ebeff3 !important;
}
.preview-template ol,
.preview-template ul {
  padding-left: 20px;
}
.preview-template ol li {
  list-style-type: auto;
}
.preview-template ul li {
  list-style-type: disc;
}
.payment-card .form-group3 ul li label {
  padding-left: 0;
}
.payment-card .form-group3 ul li {
  padding-left: 40px;
}

.payment-card .form-group3 ul li .check {
  top: 7px;
}

.payment-card .form-group3 ul li .check.check-mark {
  top: 5px;
}

.categories-list img {
  transform: scale(1);
  transition: 0.4s;
}
.categories-list:hover img {
  transform: scale(1.2);
}
.categories-list p {
  transition: 0.4s;
}
.categories-list:hover p {
  color: #1e90fe;
}

.lesson-video {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  width: 100%;
}
.lesson-video iframe {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

div[data-lastpass-icon-root="true"] {
  opacity: 0;
  display: none;
}
div[data-lastpass-infield="true"] {
  opacity: 0;
  display: none;
}
.react-viewer-toolbar {
  height: 90px !important;
}
.custom-date-picker input {
  border: 1px solid #ebeff3 !important;
  outline: none !important;
  box-shadow: none !important;
  height: 48px;
  color: #485868;
  font-size: 14px;
  font-weight: 500;
  padding-right: 20px !important;
}

.cdp-new input {
  background-color: transparent !important;
  border: none !important;
  padding-left: 75px;
  cursor: pointer;
}
.custom-date-picker > div > div {
  z-index: 50 !important;
}
.custom-date-picker ul li:hover {
  background-color: #ebf7ff;
}
.custom-date-picker div > button {
  top: 0;
}
.custom-date-picker div > button:hover {
  background-color: transparent;
  color: #000;
}
.custom-date-picker button:hover {
  background-color: #ebf7ff;
}

.notificationDropdown,
.dataCalendar {
  transition: 0.5s;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top;
}
.notificationDropdown.notificationOpen,
.dataCalendar.dataCalendarOpen {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.dashboard-messages .messages-list li:last-child {
  border-bottom: none;
}

.public-service-card {
  border-bottom: 1px solid #bec2c5;
  margin-bottom: 22px;
  padding-bottom: 22px;
}
.public-service-card:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
/* admin dashboard css start */
.team-matrics-card {
  transition: 0.4s;
}

.team-matrics-card:hover {
  background-color: #f1f4f6;
}

.team-matrics-card .number {
  transition: 0.4s;
}

.team-matrics-card:hover .number {
  background-color: #ffffff !important;
}

/* react calendar css start */
.calender-wrapper .react-calendar__navigation {
  position: relative;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  gap: 20px;
  display: flex;
  margin-bottom: 1em;
}
.calender-wrapper .react-calendar__navigation__label {
  /* position: absolute;
  left: 0;
  top: 0; */
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
  color: #1c1c1c !important;
  /* top: 50%;
  transform: translateY(-50%); */
}
.calender-wrapper .react-calendar__navigation button {
  min-width: auto;
  background: none;
  font-size: 18px;
  color: #1e90fe;
  background-color: transparent !important;
}
.calender-wrapper .react-calendar__navigation button:hover {
  background-color: transparent !important;
}
.calender-wrapper .react-calendar {
  width: 100%;
  border: none;
  min-height: 288px;
}
.dashboard_calander_slideover .calender-wrapper .react-calendar {
  min-height: auto;
}
.calender-wrapper .react-calendar .react-calendar__navigation__prev2-button,
.calender-wrapper .react-calendar .react-calendar__navigation__next2-button {
  display: none;
}
.calender-wrapper .react-calendar__tile:hover {
  background-color: #ebf7ff !important;
}
.calender-wrapper .react-calendar__tile--active,
.calender-wrapper .react-calendar__tile--active:hover {
  background-color: #d2e3fc !important;
  color: #000;
}
.calender-wrapper .react-calendar__tile--now,
.calender-wrapper .react-calendar__tile--now:hover {
  background-color: #1e90fe !important;
  color: #fff;
}
.calender-wrapper .react-calendar__month-view__weekdays__weekday {
  color: #1e90fe;
}
.calender-wrapper .react-calendar .react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}
.calender-wrapper .react-calendar .react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  margin: 0;
  border: 0;
  outline: none;
}
.calender-wrapper .react-calendar .react-calendar__tile:disabled {
  background-color: #f0f0f0 !important;
  color: #ababab;
}
/* react calendar css end */

.data-base-svg svg {
  width: 18px;
  height: 18px;
}
table tr .second-last-dropdown,
table tr:last-child .global-dropdown {
  bottom: 20px;
  top: auto;
}
.global-dropdown-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.global-dropdown-list li {
  transition: 0.4s;
}

.global-dropdown-list li svg {
  stroke: #1c1c1c;
  transition: 0.4s;
}

.global-dropdown-list li:hover {
  color: #1e90fe;
}
.global-dropdown-list li:hover svg {
  stroke: #1e90fe;
}
.alert-date-picker input {
  box-shadow: none !important;
}
.calender-wrapper .react-calendar abbr:where([title]) {
  text-decoration: none !important;
}

.profession-list:last-child ul {
  margin-bottom: 0;
}
.profession-list ul:last-child li .form-group {
  margin-bottom: 0;
}
.service-enrollment:hover {
  background-color: #ebf7ff !important;
}
/* admin dashboard css end */

.profile-accordians:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.qualifications-list li:nth-child(odd) {
  background-color: #f1f4f6;
}

/* Confetti css starts */
.particles-container {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  overflow: hidden;
  top: 0;
  left: 0;
}

.particle {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
}

.circular {
  width: var(--size);
  height: var(--size);
  background-color: var(--color);
}

.rectangular {
  width: var(--size);
  height: calc(var(--size) / 2);
  background-color: var(--color);
}

.squiggle {
  position: relative;
  width: var(--size);
  height: var(--size);
}

.squiggle path {
  stroke: var(--color);
  stroke-width: 2;
  fill: none;
}

/* Confetti css ends */

/* professional-datepicker css start */
.professional-datepicker .grid-cols-7 .tracking-wide {
  font-weight: bold;
  color: #1e90fe;
}
.professional-datepicker svg {
  color: #1e90fe;
}

/* professional-datepicker css end */

/* PDF template css starts */

.pdf-template .rpv-core__inner-page {
  background-color: transparent !important;
}
.pdf-chatbot .react-chatbot-kit-chat-container {
  width: 100%;
}
.pdf-chatbot .react-chatbot-kit-chat-inner-container {
  height: calc(100vh - 210px);
}
.pdf-chatbot
  .react-chatbot-kit-chat-bot-message-container
  .react-chatbot-kit-chat-bot-message {
  margin-left: 0px;
}
.pdf-chatbot .react-chatbot-kit-chat-message-container {
  height: 85%;
}
.line-clamp-7 {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* PDF template css ends  */

.input-wrapper .css-119tkvf {
  overflow: unset;
}

.input-wrapper .css-ekzvno {
  top: 50%;
  transform: translateY(-50%);
}
/* .message-list-wrapper .css-1va3v07{
  margin-left: 64px;
}
.message-list-wrapper .css-g5t0ri{
  margin-right: 64px;
} */
.message-list-wrapper .css-1q27vul {
  background-color: white;
  margin-left: 64px;
}
.message-list-wrapper .css-3gbhzn {
  margin-right: 64px;
  background-color: #1e90fe;
}

.privacy-policy-wrapper ul li {
  list-style-type: disc;
}

.privacy-policy-wrapper ul li ul li {
  list-style-type: circle;
}
.privacy-policy-wrapper ul li ul ul li {
  list-style-type: square;
}

/* chat css start */
.css-1q27vul {
  /* overflow-wrap: unset;
  white-space: unset; */
  max-width: 560px !important;
}
/* chat css end */

@media (max-width: 1279px) {
  .range-items li::after {
    right: -30px;
  }
  .quill-wrapper .ql-container.ql-snow {
    height: 230px;
    padding: 0 18px;
  }
}

@media (max-width: 1023px) {
  .main-header .mobile-nav .active {
    background-color: #1e90fe;
    color: #fff;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
  .health-services .container {
    margin-bottom: -54px !important;
  }
  .others .item {
    flex-direction: column;
  }
  .others .others-slider .slick-track {
    gap: 0;
  }
  .main-header {
    background-color: white;
  }
}

@media (max-width: 640px) {
  .range-items li::after {
    display: none;
  }
  .suf-credit .range-items {
    margin-top: 15px;
  }
  .form-group.form-group-lead input::before {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 320px) {
  .others-slider .slick-prev,
  .others-slider .slick-next {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: url("../src/images/arrow.svg") no-repeat;
    background-color: #ccd0d4;
    background-position: 50%;
    background-size: 17px;
    z-index: 1;
    transition: 0.4s;
    right: -17px;
  }

  .others-slider .slick-prev {
    transform: translate(0, -50%) rotate(180deg);
    left: -17px;
    right: 0;
  }

  .others-slider .slick-prev:hover,
  .others-slider .slick-prev:focus,
  .others-slider .slick-next:hover,
  .others-slider .slick-next:focus {
    background: url("../src/images/arrow.svg") no-repeat;
    background-color: #1e90fe;
    background-position: 50%;
    background-size: 17px;
  }

  .others-slider .slick-prev:before,
  .others-slider .slick-next:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .others-slider .slick-prev,
  .others-slider .slick-next {
    top: -125px;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    background: url("../src/images/arrow.svg") no-repeat;
    background-color: #ccd0d4;
    background-position: 50%;
    transform: translate(0);
    z-index: 1;
    right: 0;
    transition: 0.4s;
  }

  .others-slider .slick-prev {
    transform: rotate(180deg);
    left: auto;
    right: 94px;
  }

  .others-slider .slick-prev:hover,
  .others-slider .slick-prev:focus,
  .others-slider .slick-next:hover,
  .others-slider .slick-next:focus {
    background: url("../src/images/arrow.svg") no-repeat;
    background-color: #1e90fe;
    background-position: 50%;
  }

  .others-slider .slick-prev:before,
  .others-slider .slick-next:before {
    display: none;
  }
  .banner-bg {
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
  }
}

.test-stripe .StripeElement {
  width: 100%;
  margin-left: 14px;
}

.test-stripe-cvc .StripeElement,
.test-stripe-exp .StripeElement {
  width: 60%;
  margin-left: 14px;
}

@media (min-width: 1024px) {
  .mhc-acc-list li:first-child {
    justify-content: flex-end;
  }
  .mhc-acc-list li:last-child {
    justify-content: flex-start;
  }
}
